@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");
@import url("https://fonts.googleapis.com/css?family=VT323");


html,
body {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  font-family: "Press Start 2P", "VT323";
  color: white;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}
.moving-background {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1600px;
  background-image: url("./assets/background/cybercity copy.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

footer {
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 20px;
  text-align: center;
  font-size: 11px;
  color: white;
  margin-top: 10px;
}

@media only screen and (orientation: landscape) {
  .moving-background {
    background-size: auto 100%;
    background-position: center top;
  }
}